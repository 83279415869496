

<style lang="less" scoped>
.bg {
  width: 100%;
  height: 4rem !important;
}
</style>
<template>
  <div :id="id"
       class="bg"></div>
</template>

<script>

import echarts from "echarts";

export default {
  name: 'lineView',
  props: ['valueInfo'],
  data () {
    return {
      id: 'echarts_' + 'lineView',
    }
  },
  watch: {
    valueInfo (newVal, oldVal) {
      this.toDraw();
    },
  },
  mounted () {
    this.toDraw(); // 修改数据date
    window.onresize = () => {
      this.echartObj.resize();
    };
  },
  methods: {
    toDraw () {
      this.echartObj = echarts.init(document.getElementById(this.id));
      var option = {
        animationEasing: 'bounceInOut',
        animationDuration: function (idx) {
          // 越往后的数据延迟越大
          return 3000;
        },
        grid: {
          left: '3%',
          right: '3%',
          bottom: '14%',
          top: '18%',
          containLabel: true,
        },
        // color:[this.valueInfo.perfTrendLineColor.stationCount,this.valueInfo.perfTrendLineColor.currBalance],
        legend: {
          data: ['站点个数','时点余额(万元)'],
          bottom: 0,
          textStyle: {
            color: 'rgba(255, 255, 255, 0.65)',
            fontSize: 12
          },
          itemWidth: 10,
          itemHeight:10,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisLabel: {
              color: 'rgba(255, 255, 255, 0.65)',
              padding: [0, 0, 0, 10],
              fontSize: 11,
            },
            axisLine: {
              lineStyle: {
                color: 'rgba(255, 255, 255, 0.15)'
              }
            },
            data: this.monthDataFormat()
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '站点个数',
            min: 0,
            max: this.dataUpTo(Math.max.apply(Math, this.stationCountDataFormat()), 10),
            interval: this.dataUpTo(Math.max.apply(Math, this.stationCountDataFormat()), 10) / 5,
            nameTextStyle: {
              color: 'rgba(255, 255, 255, 0.65)',
              fontSize: 12,
            },
            axisLabel: {
              color: 'rgba(255, 255, 255, 0.65)',
              formatter: '{value}',
              fontSize: 12,
            },
            axisLine: {
              lineStyle: {
                opacity: 0,
              }
            },
          },
          {
            type: 'value',
            name: '时点余额(万元)',
            min: 0,
            max: this.dataUpTo(Math.max.apply(Math, this.currBalanceDataFormat()), 100),
            interval: this.dataUpTo(Math.max.apply(Math, this.currBalanceDataFormat()), 100) / 5,
            nameTextStyle: {
              color: 'rgba(255, 255, 255, 0.65)',
              fontSize: 12,
            },
            axisLabel: {
              color: 'rgba(255, 255, 255, 0.65)',
              formatter: '{value}',
              fontSize: 12,
            },
            axisLine: {
              opacity: 0,
            }
          }
        ],
        series: [
          {
            name: '站点个数',
            type: 'line',
            data: this.stationCountDataFormat(),
            yAxisIndex: 0,
            symbol: 'circle',//折线点设置为实心点
            symbolSize: 4,   //折线点的大小
            areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#1968FF'
                }, {
                    offset: 1,
                    color: 'rgba(0,0,0,0)'
                }])
            },
            itemStyle: {
              normal: {
                color: '#1968FF',
                // color: this.valueInfo.perfTrendLineColor.stationCount,
                lineStyle: {
                  width: 2,
                }
              }
            },

          },
          {
            name: '时点余额(万元)',
            type: 'line',
            yAxisIndex: 1,
            symbol: 'circle',//折线点设置为实心点
            symbolSize: 4,   //折线点的大小
            data: this.currBalanceDataFormat(),
            areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#0DFFB7'
                }, {
                    offset: 1,
                    color: 'rgba(0,0,0,0)'
                }]) 
            },
            itemStyle: {
              normal: {
                color: '#0DFFB7',
                // color: this.valueInfo.perfTrendLineColor.currBalance,
                lineStyle: {
                  width: 3,
                }
              }
            },
          },
        ]
      };
      this.echartObj.setOption(option);
    },
    stationCountDataFormat () {
      var tmp = [];
      for (var i = 0; i < this.valueInfo.perfTrendList.length; i++) {
        tmp.push(this.valueInfo.perfTrendList[i].stationCount)
      }
      console.log(tmp)
      return tmp.reverse();
    },
    currBalanceDataFormat () {
      var tmp = [];
      for (var i = 0; i < this.valueInfo.perfTrendList.length; i++) {
        tmp.push(this.valueInfo.perfTrendList[i].currBalance)
      }
      return tmp.reverse();
    },
    monthDataFormat () {
      var tmp = [];
      for (var i = 0; i < this.valueInfo.perfTrendList.length; i++) {
        tmp.push(this.valueInfo.perfTrendList[i].month)
      }
      console.log(tmp)
      return tmp.reverse();
    },
    dataUpTo (v, step) {
      var mod = v % step
      return mod ? v - mod + step : v
    }
  }
};
</script>

