<template>
  <div style="height:0.4rem;text-align: center">
    <!--        <span style="white-space: pre;margin-left: 2vw;color: rgba(0,191,255,1);font-size: 1vw;font-weight: bold">{{title}}</span>-->
    <vueInfiniteAutoScroll :data="list"
                           :option="{force:true,speed:0.2}"
                           class="scroller-wrap"
                           key="s1">
      <template slot-scope="props">
        <span v-text="props.item"
              style="color: #fff; opacity:0.76; font-size: 0.24rem;white-space: pre;margin-bottom:0.24rem"></span>
      </template>
    </vueInfiniteAutoScroll>
  </div>
</template>

<script>
import vueInfiniteAutoScroll from '@/components/vue-infinite-auto-scroll.vue'
export default {
  name: "MarqueeRollUpView",
  props: ['valueInfo'],
  components: { vueInfiniteAutoScroll },
  data () {
    return {
      list: [

      ],
    }
  },
  mounted () {
    this.toSetList();
  },
  watch: {
    valueInfo (newVal, oldVal) {
      this.toSetList();
    },

  },
  methods: {
    toSetList () {
      var tmp = [];
      for (var i = 0; i < this.valueInfo.stationCreateInfo.length; i++) {
        tmp.push('创建时间     ' + this.timestampToTime(this.valueInfo.stationCreateInfo[i].createTime) )
        tmp.push('站点位置     ' + (this.valueInfo.stationCreateInfo[i].stationLocation.length > 18 ? this.valueInfo.stationCreateInfo[i].stationLocation.substring(0, 18) + '...' : this.valueInfo.stationCreateInfo[i].stationLocation))
        tmp.push('------------------------------------')
      }
      this.list = tmp;
    },
    timestampToTime (timestamp) {
      var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear();
      var M = date.getMonth();
      var D = date.getDate();
      var h = date.getHours();
      var m = date.getMinutes();
      var s = date.getSeconds();
      return Y + '-' + this.add0(M) + '-' + this.add0(D) + '  ' + this.add0(h) + ':' + this.add0(m) + ':' + this.add0(s);
    },
    add0 (m) {
      return m < 10 ? '0' + m : m
    }
  }
}
</script>

<style lang="less" scoped>
.scroller-wrap {
  height: 0.8rem;
  width: 100%;
  padding: 0 0.8rem;
  text-align: left;
  margin-top: 0.4rem;
}
</style>
