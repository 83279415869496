<template>
  <div class="bigDataPhone">
    <div class="amountCount">
      <div class="title">当前时点余额（{{ pushData.currBalanceUnit }}）</div>
      <div class="money">{{ pushData.currBalance | numFilter }}</div>
    </div>
    <div class="count flex">
      <div class="one">
        <div>已上线站点数</div>
        <div>
          <span>{{ pushData.stationCount }}</span> 个
        </div>
      </div>
      <div>
        <div>客户数</div>
        <div>
          <span>{{ pushData.cardNum }}</span> 个
        </div>
      </div>
    </div>
    <van-tabs v-model="active" scrollspy sticky color="#01A970" title-inactive-color="#525B62" title-active-color="#D9DADA" background="linear-gradient(180deg, #0A0F14 0%, #0C1016 100%)">
      <van-tab title="站点地图">
        <div class="site-map">
           <china :valueInfo="pushData"></china> 
           <MarqueeRollUpView :valueInfo="pushData"></MarqueeRollUpView>
        </div>
      </van-tab>
      <van-tab title="业绩统计">
        <div class="box yeji-top">
          <div class="title">{{pushData.firstTop.name}}</div>
          <top-view :type="'yeji'" :showColor="'rgba(88,137,255,1)'" :valueInfo="pushData.firstTop.value"></top-view>
        </div>
        <div class="box yeji-top">
          <div class="title">{{pushData.secondTop.name}}</div>
          <top-view :type="'bank'" :showColor="'rgba(255,215,0,1)'" :valueInfo="pushData.secondTop.value"></top-view>
        </div>
        <div class="box qushi">
          <div class="title">站点业绩趋势图</div>
            <lineView :valueInfo="pushData"></lineView>
        </div>
        <div class="box sun">
          <div class="title">业绩分布</div>
          <pieView :valueInfo="pushData"></pieView>
          <div class="desc">外环：时点余额</div>
          <div class="desc">内环：客户数</div>
        </div>
      </van-tab>
      <van-tab title="乡村特色服务">
        <div class="box special-country">
          <div class="title">乡村特色服务</div>
          <specialServiceCell v-for="(item,index) in pushData.operateDataList"
                              :startValue="item.operateValue"
                              :key="index"
                              :index="index"
                              :unit="item.unit"
                              :title="item.operateName"
                              :interval="1000"
                              :isOnline="item.active == 1 ? true : false"
                              :iconName="bigDataImgBaseUrl+item.picUrl"
          ></specialServiceCell>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import china from "./china";
import MarqueeRollUpView from "./MarqueeRollUpView";
import topView from "./topView";
import lineView from './lineView'
import pieView from './pieView'
import specialServiceCell from './specialServiceCell'
import {uwStatisticAction} from "../../../utils/util";
export default {
  components: { china, MarqueeRollUpView, topView, lineView, pieView, specialServiceCell },
  data() {
    return {
      active: 0,
      index: 0,
      pushData: {},
      liveInfo: 'h5 is live a',
      bigDataImgBaseUrl: null,
    }
  },
  filters: {
    numFilter(value) {
      return (+value || 0)
        .toFixed(2)
        .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')
    },
  },
  methods: {
    //初始化weosocket
    initWebSocket() {
      if (typeof WebSocket === 'undefined') {
        alert('您的浏览器不支持WebSocket')
        return false
      }
      // var wsuri = 'ws://(后端WebSocket地址)/websocket/' + this.userId + '/' + this.appid // websocket地址
      // var wsuri = 'ws://10.101.0.93:8089/websocket/bigScreen'
      // var wsuri = 'ws://172.23.42.139:8090/websocket/bigScreen'// var wsuri =
      // var wsuri = 'wss://bigscreen.wowfintech.net:7090/websocket/bigScreen'
      // var wsuri = 'wss://h5.cunkouyizhan.com/screen/websocket/bigScreen'
      var wsuri =
        process.env.NODE_ENV == 'production' &&
        window.location.href.indexOf('sit-villagestation') == -1
          ? 'wss://h5.cunkouyizhan.com/screen/websocket/bigScreen'
          : 'wss://sit-admin.wowfintech.net/screen/websocket/bigScreen'
      this.websock = new WebSocket(wsuri)
      this.websock.onopen = this.websocketonopen
      this.websock.onmessage = this.websocketonmessage
      this.websock.onerror = this.websocketonerror
      this.websock.onclose = this.websocketclose
    },
    //连接成功
    websocketonopen() {
      console.log('WebSocket连接成功')
      if (this.restartTimer) {
        // 注意在vue实例销毁前，清除我们的定时器
        clearInterval(this.restartTimer)
      }
      // 添加心跳检测，每30秒发一次数据，防止连接断开（这跟服务器的设置有关，如果服务器没有设置每隔多长时间不发消息断开，可以不进行心跳设置）
      let self = this
      this.timer = setInterval(() => {
        try {
          self.websock.send(this.liveInfo)
        } catch (err) {
          console.log('断开了：' + err)
          // self.restartSocket();
        }
      }, 30000)
    },
    //接收后端返回的数据，可以根据需要进行处理
    websocketonmessage(e) {
      console.log('收到消息----', e, '------')

      if (e.data.indexOf(this.liveInfo) != -1) {
        return
      }
      let dataJson = JSON.parse(e.data)
      if (
        dataJson != undefined &&
        Object.prototype.toString.call(dataJson) === '[object Object]'
      ) {
        this.pushData = dataJson
        console.log('收到有效消息', this.pushData)
      }
    },
    //连接建立失败重连
    websocketonerror(e) {
      console.log(`连接失败的信息：`, e)
      // this.restartSocket(); // 连接失败后尝试重新连接
    },
    //关闭连接
    websocketclose(e) {
      let self = this
      console.log('断开连接', e)
      this.restartTimer = setInterval(() => {
        self.restartSocket()
      }, 120000)
    },
    closeSocket() {
      //页面销毁时关闭ws连接
      if (this.websock) {
        this.websock.close() // 关闭websocket
      }
      if (this.timer) {
        // 注意在vue实例销毁前，清除我们的定时器
        clearInterval(this.timer)
      }
      if (this.restartTimer) {
        // 注意在vue实例销毁前，清除我们的定时器
        clearInterval(this.restartTimer)
      }
    },
    restartSocket() {
      this.closeSocket()
      this.initWebSocket()
    },
    getMonth() {
      var date = new Date()
      return date.getMonth() + 1
    },
  },
  created() {
    this.closeSocket()
    this.initWebSocket()
    uwStatisticAction('/bigDataPhone','数据大屏手机端')
  },
  destroyed() {
    //页面销毁时关闭ws连接
    if (this.websock) {
      this.websock.close() // 关闭websocket
    }
    if (this.timer) {
      // 注意在vue实例销毁前，清除我们的定时器
      clearInterval(this.timer)
    }
    if (this.restartTimer) {
      // 注意在vue实例销毁前，清除我们的定时器
      clearInterval(this.restartTimer)
    }
  },
  beforeDestroy() {
    this.websocketclose()
  },
}
</script>

<style lang="less" scoped>
.bigDataPhone {
  color: #d9dada;
  background: linear-gradient(180deg, #0a0f14 0%, #0c1016 100%);
  padding-bottom: 0.34rem;
  .flex {
    display: flex;
  }
  .yeji-top{
    width: 6.9rem;
    height: 3.9rem;
    margin: 0.4rem auto 0.3rem;
    z-index: 10;
    background: #121822;
    padding: 0.32rem 0;
  }
  .box{
    .title{
      color: #DBDCDD;
      margin: 0 0.45rem 0.1rem;
      font-size: 0.32rem;
      position: relative;
      &::before{
        content: '';
        width: 0.1rem;
        height: 0.1rem;
        background: #00BB6F;
        border-radius: 0.05rem;
        position: absolute;
        left: -0.2rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .qushi{
    width: 6.9rem;
    height: 5rem;
    margin: 0.4rem auto 0.3rem;
    z-index: 10;
    background: #121822;
    padding: 0.32rem 0;
  }
  .sun{
   width: 6.9rem;
    height: 4.8rem;
    margin: 0.4rem auto 0.3rem;
    z-index: 10;
    background: #121822;
    padding: 0.32rem 0;
    .desc{
      display: inline-block;
      color: rgba(255, 255, 255, 0.65);
      font-size: 0.21rem;
      margin-right: 0.5rem;
      margin-left: 0.28rem;
      margin-top: 0.2rem;
    }
  }
  .special-country{
    width: 6.9rem;
    height: 4.42rem;
    margin: 0.4rem auto 0.3rem;
    z-index: 10;
    background: #121822;
    padding: 0.32rem 0;
    .title{
      margin-bottom: 0.38rem;
    }
  }
  .amountCount {
    text-align: center;
    .title {
      color: #f7f8f9;
      font-size: 0.32rem;
      padding-top: 0.44rem;
    }
    .money {
      margin-top: 0.2rem;
      color: #00a55e;
      font-size: 1.1rem;
      font-family: 'UnidreamLED';
    }
  }
  .site-map{
    height:7.8rem;
    width: 6.9rem;
    background: #121822;
    border-radius: 0.16rem;
    margin: 0 auto 0.3rem;
  }
  @font-face {
    font-family: 'UnidreamLED';
    src: url('./../../../assets/fonts/LESLIE.TTF') format('truetype'); //UnidreamLED.ttf在项目中的位置
  }
  .count {
    width: 6.9rem;
    height: 1.4rem;
    background: #121822;
    color: rgba(255, 255, 255, 0.65);
    font-size: 0.24rem;
    text-align: center;
    align-items: center;
    margin: 0.26rem auto 0;
    div {
      flex: 1;
    }
    .one {
      position: relative;
      &::after {
        content: '';
        position: absolute; 
        width: 0.01rem;
        height: 0.7rem;
        background: linear-gradient(rgba(0,0,0,0.2) 0%, #00A55E 50%, rgba(0,0,0,0.2) 100%);
        right: 0;
        top: 0.1rem;
      }
    }
    span {
      font-size: 0.52rem;
      color: #01a970;
    }
  }
}
</style>
