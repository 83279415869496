<style lang="less" scoped>
.o-echarts {
  width: 100%;
  height: 3rem;
}
</style>
<template>
  <div :id="id"
       class="o-echarts"></div>
</template>

<script>

import echarts from "echarts";

export default {
  name: 'topView',
  props: ['showColor', 'showTitle', 'valueInfo', 'type'],
  data () {
    return {
      id: 'echarts_' + this.showColor,
    }
  },
  watch: {
    valueInfo (newVal, oldVal) {
      this.toDraw();
    },
  },
  mounted () {
    this.toDraw();
    window.onresize = () => {
      this.echartObj.resize();
    };
  },
  methods: {
    toDraw () {
      this.echartObj = echarts.init(document.getElementById(this.id));
      let colorList
      if(this.type == 'yeji'){
        colorList = [new echarts.graphic.LinearGradient(
          1, 0, 0, 0,
          [
              {offset: 0, color: '#3CCEE7'},
              {offset: 1, color: '#684ECF'}
          ]
        ), new echarts.graphic.LinearGradient(
          1, 0, 0, 0,
          [
              {offset: 0, color: '#943CBC'},
              {offset: 1, color: '#6328A9'}
          ]
        )]
      }
      if(this.type == 'bank'){
        colorList = [new echarts.graphic.LinearGradient(
          1, 0, 0, 0,
          [
              {offset: 0, color: '#7BD432'},
              {offset: 1, color: '#157251'}
          ]
        ), new echarts.graphic.LinearGradient(
          1, 0, 0, 0,
          [
              {offset: 0, color: '#62ADF8'},
              {offset: 1, color: '#027BF7'}
          ]
        )]
      }
      
      let data = echarts.util.map(this.getObjectValues(this.valueInfo), function (item, index) {
        return {
          value: item,
          itemStyle: {
              color: colorList[index%2]
          }
        };
      });

      var option = {
        grid: {
          top: '5%',
          left: '3%',
          right: '3%',
          bottom: 0,
          containLabel: true,
        },
        xAxis: [
          {
            type: 'value',
            show: false,
            axisTick: {
              show: true,
            },
            axisLine: {
              show: false,
            },
            max: function (value) {
              return value.max + (value.max * 0.4);
            }
          }
        ],
        yAxis: [
          {
            inverse: true,
            type: 'category',
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              fontSize: 14,          
              color: 'rgba(255, 255, 255, 0.8)',
              padding: [0, 0, 0, 0],
              // width: 50,
              // margin: 10
            },
            data: this.getObjectKeys(this.valueInfo)
          },
          {
            inverse: true,
            type: 'category',
            axisTick: 'none',
            axisLine: 'none',
            axisLabel: {
              fontSize: 14,          
              color: 'rgba(255, 255, 255, 0.8)',
              padding: [0, -40, 0, 0],
              // width: 50,
              margin: 5,
              align: 'right'
            },
            data: data
          }
        ],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5;
        },
        series: [
          {
            type: 'bar',
            // label: {
            //   show: true,
            //   position: 'right',
            //   fontSize: 14,
            //   color: 'rgba(255, 255, 255, 0.8)',
            // },
            barWidth: 10, // 柱图宽度
            barMaxWidth: 10, // 最大宽度
            // barCategoryGap: '20%',
            // roundCap: true,
            itemStyle: {
              barBorderRadius: 5,
            },
            // data: this.getObjectValues(this.valueInfo)
            data: data
          },
        ]
      };
      this.echartObj.setOption(option);
    },
    getObjectKeys (object) {
      var keys = [];
      for (var property in object){
        if(property.indexOf('信用社') > -1){
          property = property.replace('信用社', '')
        }
        keys.push(property);
      }
      return keys;
    },

    getObjectValues (object) {
      var values = [];
      for (var property in object)
        values.push(object[property]);
      return values;
    }
  }
};
</script>
