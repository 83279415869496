<style lang="less" scoped>
.o-echarts {
  width: 6.9rem;
  height: 6rem;
  background-color: rgba(0, 0, 0, 0);
  color: #031525;
}
</style>
<template>
  <div :id="id" class="o-echarts"></div>
</template>

<script>
import echarts from 'echarts';
import JSON from '../data-1528971808162-BkOXf61WX.json';
// import JSON from './all.json';
export default {
  name: 'china',
  props: ['valueInfo'],
  data () {
    return {
      id: 'echarts_' + 'china',
    }
  },
  watch: {
    valueInfo (newVal, oldVal) {
      this.toDraw();
    },
  },
  mounted () {
    this.toDraw();
    window.onresize = () => {
      this.echartObj.resize();
    };
  },
  methods: {
    toDraw () {
      // 行政区数据
      var geoCoordMap = {
        '台湾省': [121.5135, 25.0308],
        '黑龙江省': [127.9688, 45.368],
        '内蒙古自治区': [110.3467, 41.4899],
        "吉林省": [125.8154, 44.2584],
        '北京市': [116.4551, 40.2539],
        "辽宁省": [123.1238, 42.1216],
        "河北省": [114.4995, 38.1006],
        "天津市": [117.4219, 39.4189],
        "山西省": [112.3352, 37.9413],
        "陕西省": [109.1162, 34.2004],
        "甘肃省": [103.5901, 36.3043],
        "宁夏回族自治区": [106.3586, 38.1775],
        "青海省": [101.4038, 36.8207],
        "新疆维吾尔自治区": [87.9236, 43.5883],
        "西藏自治区": [91.11, 29.97],
        "四川省": [103.9526, 30.7617],
        "重庆市": [108.384366, 30.439702],
        "山东省": [117.1582, 36.8701],
        "河南省": [113.4668, 34.6234],
        "江苏省": [118.8062, 31.9208],
        "安徽省": [117.29, 32.0581],
        "湖北省": [114.3896, 30.6628],
        "浙江省": [119.5313, 29.8773],
        "福建省": [119.4543, 25.9222],
        "江西省": [116.0046, 28.6633],
        "湖南省": [113.0823, 28.2568],
        "贵州省": [106.6992, 26.7682],
        "云南省": [102.9199, 25.4663],
        "广东省": [113.12244, 23.009505],
        "广西壮族自治区": [108.479, 23.1152],
        "海南省": [110.3893, 19.8516],
        '上海市': [121.4648, 31.2891],
        "香港": [114.08, 22.20],
        '澳门': [113.33, 22.13],
      };
      // 展示数据
      var data = [
        { name: "北京市", value: 0 },
        { name: "天津市", value: 0 },
        { name: "河北省", value: 0 },
        { name: "山西省", value: 0 },
        { name: "内蒙古自治区", value: 0 },
        { name: "辽宁省", value: 0 },
        { name: "吉林省", value: 0 },
        { name: "黑龙江省", value: 0 },
        { name: "上海市", value: 0 },
        { name: "江苏省", value: 0 },
        { name: "浙江省", value: 0 },
        { name: "安徽省", value: 0 },
        { name: "福建省", value: 0 },
        { name: "江西省", value: 0 },
        { name: "山东省", value: 0 },
        { name: "河南省", value: 0 },
        { name: "湖北省", value: 0 },
        { name: "湖南省", value: 0 },
        { name: "重庆市", value: 0 },
        { name: "四川省", value: 0 },
        { name: "贵州省", value: 0 },
        { name: "云南省", value: 0 },
        { name: "西藏自治区", value: 0 },
        { name: "陕西省", value: 0 },
        { name: "甘肃省", value: 0 },
        { name: "青海省", value: 0 },
        { name: "宁夏回族自治区", value: 0 },
        { name: "新疆维吾尔自治区", value: 0 },
        { name: "广东省", value: 0 },
        { name: "广西壮族自治区", value: 0 },
        { name: "海南省", value: 0 },
        { name: "台湾省", value: 0 },
        { name: "香港", value: 0 },
        { name: "澳门", value: 0 },
      ];
      for (var i = 0; i < this.getObjectKeys(this.valueInfo.hasDataStationMap).length; i++) {
        for (var j = 0; j < data.length; j++) {
          if (data[j].name == this.getObjectKeys(this.valueInfo.hasDataStationMap)[i]) {
            data[j].value = this.getObjectValues(this.valueInfo.hasDataStationMap)[i]
          }
        }
      }

      var stationArr = []
      for (var k = 0; k < this.valueInfo.stationLocateList.length; k++) {
        stationArr.push({ value: [this.valueInfo.stationLocateList[k].lng, this.valueInfo.stationLocateList[k].lat] })
      }

      this.echartObj = echarts.init(document.getElementById(this.id));
      echarts.registerMap('china', JSON);

      // 处理需要展示的数据
      var convertData = function (data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
          var geoCoord = geoCoordMap[data[i].name];
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value)
            });
          }
        }
        return res;
      };

      // 处理需要展示的数据
      var convertData11 = function (data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
          var geoCoord = geoCoordMap[data[i].name];
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value)
            });
          }
        }
        return res;
      };

      var formatData = function (data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
          var dataItem = data[i];
          var fromCoord = geoCoordMap[dataItem[0].name];
          var toCoord = geoCoordMap[dataItem[1].name];
          if (fromCoord && toCoord) {
            res.push([{
              coord: toCoord
            }, {
              coord: fromCoord
            }]);
          }
        }
        return res;
      };

      var option = {
        // 渐变色
        // backgroundColor: 'transparent',// 背景色
        visualMap: {
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 8,
          align: "auto",
          showLabel: true,
          text: ['有数据站点分布'],
          orient: 'horizontal',
          type: 'piecewise',
          textStyle: {
            color: '#403D7D',
            fontSize: 10,
          },
          padding: [50, 20, 10, 20],
          splitList: [
            {
              start: this.valueInfo.stationCountLevel.level1.bottom,
              end: this.valueInfo.stationCountLevel.level1.top,
              label: this.valueInfo.stationCountLevel.level1.desc,
              color: '#0095FF'
              // color: this.valueInfo.stationCountLevel.level1.color,

            },
            {
              start: this.valueInfo.stationCountLevel.level2.bottom,
              end: this.valueInfo.stationCountLevel.level2.top,
              label: this.valueInfo.stationCountLevel.level2.desc,
              color: '#4BB9A9'
              // color: this.valueInfo.stationCountLevel.level2.color,
            },
            {
              start: this.valueInfo.stationCountLevel.level3.bottom,
              end: this.valueInfo.stationCountLevel.level3.top,
              label: this.valueInfo.stationCountLevel.level3.desc,
              color: '#00BB6F'
              // color: this.valueInfo.stationCountLevel.level3.color,
            },
            {
              start: this.valueInfo.stationCountLevel.level4.bottom,
              label: this.valueInfo.stationCountLevel.level4.desc,
              color: '#84E069'
              // color: this.valueInfo.stationCountLevel.level4.color,
            }
          ]
        },
        geo: { // 地图样式
          map: 'china',
          aspectScale: 0.75,
          layoutCenter: ["50%", "50%"], //地图位置
          layoutSize: '100%',
          itemStyle: {
            normal: {
              // shadowColor: '#276fce',
              // shadowOffsetX: 0,
              // shadowOffsetY: 15,
              opacity: 0.69,
              areaColor: '#2D2D2D'
            }
          },
        },
        // 地区样式
        series: [ 
        {
          type: 'map',
            mapType: 'china',
            aspectScale: 0.75,
            layoutCenter: ["50%", "50%"], //地图位置
            layoutSize: '100%',
            zoom: 1, //当前视角的缩放比例
            // roam: true, //是否开启平游或缩放
            scaleLimit: { //滚轮缩放的极限控制
                min: 1,
                max: 2
            },
            itemStyle: {
                normal: {
                    areaColor: '#0c274b',
                    borderColor: '#3C9074',
                    borderWidth: 1
                },
                emphasis: {
                    areaColor: '#5c9075',
                    label: {
                        show: false
                    }

                }
            },
          data: data
        },
        

        ]
      };
      this.echartObj.setOption(option);
    },
    getObjectKeys (object) {
      var keys = [];
      for (var property in object)
        keys.push(property);
      return keys;
    },

    getObjectValues (object) {
      var values = [];
      for (var property in object)
        values.push(object[property]);
      return values;
    }
  }
};
</script>
