<style lang="less" scoped>
.o-echarts {
  width: 100%;
  height: 3rem;
}
</style>
<template>
  <div class="o-echarts">
    <div :id="id" class="o-echarts"></div>
  </div>
</template>

<script>
import echarts from 'echarts'

export default {
  name: 'pieView',
  props: ['valueInfo'],
  data() {
    return {
      id: 'echarts_' + 'pieView',
      echartObj: null,
    }
  },
  destroyed() {
    if (this.timer) {
      // 注意在vue实例销毁前，清除我们的定时器
      clearInterval(this.timer)
    }
  },
  watch: {
    valueInfo(newVal, oldVal) {
      this.toDraw()
    },
  },
  mounted() {
    this.toDraw()
    // 定时刷新时间
    window.onresize = () => {
      this.echartObj.resize()
    }
  },
  methods: {
    toDraw() {
      this.echartObj = echarts.init(document.getElementById(this.id), 'light')
      var option = {
        animationEasing: 'quinticInOut',
        animationDuration: function(idx) {
          // 越往后的数据延迟越大
          return 2000
        },
        legend: {
          orient: 'vertical',
          right: 40,
          itemWidth: 10,
          itemHeight: 10,
          top: 10,
          borderRadius: 5, 
          itemGap: 15,
          // data: this.getObjectKeys(this.valueInfo.provCardPercent),
          textStyle: {
            color: 'white',
            fontSize: 12,
          },
        },
        series: [
          {
            name: '时点余额',
            type: 'pie',
            selectedMode: 'single',
            radius: ['45%', '60%'],
            center: ['30%', '50%'],
            symbol: 'circle',//折线点设置为实心点
            symbolSize: 4,   //折线点的大小
            label: {
              position: 'inside',
              formatter: '{d}%',
              fontSize: 12,
              color: 'white',
            },
            labelLine: {
              show: false,
            },
            itemStyle:{
              color:function(params){
                return ['#17B6BF','#2256D9','#4B27C0','#6459F7','#0DEFCF'][params.dataIndex];
              }
            },
            data: this.cardNumDataFormat().slice(0,5),
          },
          {
            name: '客户数',
            type: 'pie',
            radius: ['70%', '85%'],
            center: ['30%', '50%'],
            symbol: 'circle',//折线点设置为实心点
            symbolSize: 4,   //折线点的大小
            label: {
              position: 'inner',
              formatter: '{d}%',
              fontSize: 12,
              color: 'white',
            },
            labelLine: {
              length: 1,
              length2: 1,
            },
            itemStyle:{
              color:function(params){
                return ['#17B6BF','#2256D9','#4B27C0','#6459F7','#0DEFCF'][params.dataIndex];
              }
            },
            data: this.currBalanceDataFormat().slice(0,5),
          },
        ],
      }
      this.echartObj.setOption(option)
    },
    currBalanceDataFormat() {
      var tmp = []
      for (
        var i = 0;
        i < this.getObjectKeys(this.valueInfo.provPerfPercent).length;
        i++
      ) {
        tmp.push({
          name: this.getObjectKeys(this.valueInfo.provPerfPercent)[i],
          value: this.getObjectValues(this.valueInfo.provPerfPercent)[i],
        })
      }
      console.log(tmp)
      return tmp
    },
    cardNumDataFormat() {
      var tmp = []
      for (
        var i = 0;
        i < this.getObjectKeys(this.valueInfo.provCardPercent).length;
        i++
      ) {
        tmp.push({
          name: this.getObjectKeys(this.valueInfo.provCardPercent)[i],
          value: this.getObjectValues(this.valueInfo.provCardPercent)[i],
        })
      }
      console.log(tmp)
      return tmp
    },
    getObjectKeys(object) {
      var keys = []
      for (var property in object) keys.push(property)
      return keys
    },
    getObjectValues(object) {
      var values = []
      for (var property in object) values.push(object[property])
      return values
    },
  },
}
</script>
